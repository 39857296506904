import React, { useEffect, useState } from 'react';
import '../assets/style.css';

import Cookies from '../assets/images/acup-flavors/Cookies and Cream1.png';
import Double from '../assets/images/acup-flavors/Duble Chocolate1.png';
import Vanilla from '../assets/images/acup-flavors/Vanilla Dates1.png';
import Crispy from '../assets/images/acup-flavors/Crispy Chocolate15.png';

function Preloader() {
  const [currentImage, setCurrentImage] = useState(1);
  const [preloaderVisible, setPreloaderVisible] = useState(true);

  useEffect(() => {
    // Automatically cycle through images every 3 seconds (adjust duration as needed)
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === 4 ? 1 : prevImage + 1));
    }, 500); // Change images every 3 seconds (adjust as needed)

    // Hide the preloader after a certain duration (adjust as needed)
    setTimeout(() => {
      setPreloaderVisible(false);
      clearInterval(interval);
      // After the preloader time ends, remove the overflow: hidden from the body
      document.body.style.overflow = 'auto';
    }, 8000); // Set the time for preloader visibility (adjust as needed)

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`preloader-container ${preloaderVisible ? 'visible' : 'hidden'}`}>
      <div className="loader-container">
        <div className="preloader">
          {currentImage === 1 && <img src={Cookies} className='img-fluid no-animation' alt='loading' loading='lazy' />}
          {currentImage === 2 && <img src={Double} className='img-fluid no-animation' alt='loading' loading='lazy' />}
          {currentImage === 3 && <img src={Vanilla} className='img-fluid no-animation' alt='loading' loading='lazy' />}
          {currentImage === 4 && <img src={Crispy} className='img-fluid no-animation' alt='loading' loading='lazy' />}
        </div>
      </div>
    </div>
  );
}

export default Preloader;
