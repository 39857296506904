import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, Routes, Route, useLocation, useNavigate } from "react-router-dom";

import '../../assets/style.css';
import logoHome from '../../assets/images/logomain.png';
import logo2 from '../../assets/images/redlogo.png';

import Footer from "./footer";
import Home from "../home/home";

const ShopLazy = React.lazy(() => import('../shop/shop'))
const LearnLazy = React.lazy(() => import('../learn/learn'))
const FindLazy = React.lazy(() => import('../findUs/findUs'))
const ProductDetailsLazy = React.lazy(() => import('../shop/productDetails'))

function Header(props) {
  const [logo, setLogo] = useState(logoHome);
  const { loading } = props;
  const navRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === '/') {
      setLogo(logoHome);
    } else {
      setLogo(logo2);
    }
  }, [location.pathname]);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const navigateAndCloseNav = (path) => {
    navigate(path);
    showNavbar(); // Close the navigation
  };

  return (
    <>
      <header expand="lg">
        <Link to="/">
          <img alt="" src={logo} className="align-top logo" />
        </Link>
        <nav ref={navRef} className="links d-flex flex-grow-1">
          <Link to="/Shop" id="link" onClick={() => navigateAndCloseNav('/Shop')}>Shop</Link>
          <Link to="/Learn" id="link" onClick={() => navigateAndCloseNav('/Learn')}>Learn</Link>
          <Link to="/FindUs" id="link" onClick={() => navigateAndCloseNav('/FindUs')}>Find Us</Link>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>

      <Routes>
        <Route path='/' element={<Home loading={loading} />} />
        <Route path='/Shop' element={<React.Suspense> <ShopLazy /> </React.Suspense>} />
        <Route path='/product/:id' element={<React.Suspense> <ProductDetailsLazy /> </React.Suspense>} />
        <Route path='/Learn' element={<React.Suspense> <LearnLazy /> </React.Suspense>} />
        <Route path='/FindUs' element={<React.Suspense> <FindLazy /> </React.Suspense>} />
      </Routes>

      <Footer/>
    </>
  )
}

export default Header;
