import React from "react";
import '../../assets/style.css';
import { BsInstagram } from "react-icons/bs";
import { FaTiktok, FaFacebookF } from "react-icons/fa";

function Footer(){
    const instagramUsername = 'abrands_int';
    const facebookPageName = 'ACUPPROTEINCUP';
    const tiktokUsername = 'abrands.int';
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  
    const openInstagramLink = () => {
      if (isMobile) {
        // Open the Instagram app on mobile
        window.location.href = `instagram://user?username=${instagramUsername}`;

      } else {
        // Open Instagram in a new browser tab on desktop
        window.open(`https://www.instagram.com/${instagramUsername}/`, '_blank');
      }
    };
    const openFacebookLink = () => {
       
          // Open Instagram in a new browser tab on desktop
          window.open(`https://www.facebook.com/${facebookPageName}`, '_blank');
        }
      const openTikTokLink = () => {
        
          window.open(`https://www.tiktok.com/@${tiktokUsername}`, '_blank');
        }
    
    return(
        <section className="footer">
            <div className="container text-center">
                <div className="content-Footer">
            <div className="rectangle">
                <h2>ABRANDS IS COMMITTED TO
                DELIVERING PREMIUM QUALITY.</h2>
            </div>
            </div>
            </div>
            
                <div className="social">
                  <div className="link">
            <div className="circles">
               
            <div className="circle" style={{ cursor: 'pointer'}}>
                    <FaFacebookF   onClick={openFacebookLink} className="icon"/>
                </div>

              
                <div className="circle" style={{ cursor: 'pointer'}}>
                    <BsInstagram onClick={openInstagramLink} className="icon"/>
                </div>
           
              
                <div className="circle" style={{ cursor: 'pointer'}}>
                <FaTiktok  onClick={openTikTokLink}  className="icon"/>
                </div>
           
            </div>
                <h2 className="text-center user">Abrands_int</h2>
                </div>
            <div className="end-footer">
                <h5>© 2023 Abrands_int</h5>
                </div>
</div>
        </section>
    )
}

export default Footer;