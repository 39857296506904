import React, { useEffect} from 'react';

import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

// import { Routes, Route } from 'react-router-dom';

import { LazyLoadImage } from 'react-lazy-load-image-component';


function TopSeller (props) {

    useEffect(() => {
        AOS.init();
      }, []);
    
    const {id, productflavor, Image2,Image, protein, calories, category} = props.data;

    return (
        <div className="col-lg" >
        <div className="product"  data-aos="zoom-in" data-aos-duration="1000" >
            <Link id='toProd'to={`/product/${id}`}>
                <div className="image-view">
                {category === 'A-Cup' ? (

    <LazyLoadImage
    src={Image}
    className="img-fluid topimages"
    width={350}
    alt=""
    />

    
  ) : category === 'A-Jar'?(

    <LazyLoadImage
    src={Image2}
    className="img-fluid topimages jarimage"
    width={350}
    alt=""
    />
  ):(
    
    <LazyLoadImage
    src={Image2}
    className="img-fluid topimages barimage"
    width={100}
    alt=""
    />
  )}
            {/* {condition1 ? (
    <Component1 />
) : condition2 ? (
    <Component2 />
) : (
    <DefaultComponent />
)} */}
            </div>
            <div className="info">
            <h1 className='topname'>{productflavor}</h1>
            <div className="general text-center">
                        <h4 className="">{protein}</h4>
                        <h4 className="">{calories}</h4>
            </div>
            </div>
            </Link>
        </div>



    </div>
    )
};


export default TopSeller;


export const ACup = (props) => {
    const {id, productflavor, protein, calories, Image} = props.data;
    return (
        <div className="col-lg-3">
        <div className="product">
            <Link id='toProd'  to={`/product/${id}`}>
                        <div className="image-view">

                        <LazyLoadImage
                        src={Image}
                        alt="acup"
                        height={'auto'}
                        width={'100%'}
                        />

                 
                        </div>
                        
            <div className="info">
            <h3>{productflavor}</h3>
            <div className="general2">
                        <h5 className="">{protein}</h5>
                        <h5 className="">{calories}</h5>
            </div>
            </div></Link>
                    </div>
                    </div>
    )
};



export const ABar = (props) => {
    const {id, productflavor, protein,calories, Image} = props.data;
    return (
        <div className="col-lg">
        <div className="product">
            <Link id='toProd'  to={`/product/${id}`}>
                        <div className="image-view">
                        <LazyLoadImage
                        src={Image}
                        alt="acup"
                        height={'auto'}
                        width={'80%'}
                        />
                        </div>
                        <br/>
                        <br/>
            <div  className="info">
            <h3>{productflavor}</h3>
            <div className="general2">
                        <h5 className="">{protein}</h5>
                        <h5 className="">{calories}</h5>
            </div>
            </div></Link>
                    </div>
                    </div>
    )
};


export const AJar = (props) => {
    const {id, productflavor,calories,protein, Image} = props.data;
    return (
        <div className="col-lg-4">
        <div className="product">
            <Link id='toProd'  to={`/product/${id}`}>
                        <div className="image-view">
                        <LazyLoadImage
                        src={Image}
                        alt="acup"
                        height={'auto'}
                        width={'100%'}
                        />
                        </div>
                        
            <div  className="info">
            <h3>{productflavor}</h3>
            <div className="general2 jar">
                        <h5 className="t">{protein}</h5>
                        <h5 className="">{calories}</h5>
            </div>
            </div></Link>
                    </div>

     

                    </div>
    )
};

