import React,{useEffect, useRef} from 'react';

import 'swiper/css/pagination';
import '../../assets/style.css';
import 'swiper/css';

import { PRODUCTS } from '../../products';

import TopSeller  from '../shop/product';
// import halfCup from '../assets/images/half.png';

import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'react-router-dom';
import { Pagination, Autoplay} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import AOS from 'aos';
import 'aos/dist/aos.css';

import ACUP from '../../assets/images/logos/ACUP.png'
import ABAR from '../../assets/images/logos/ABAR.png'
import ABUTTER from '../../assets/images/logos/ABUTTER.png'
import desc from '../../assets/images/cupdesc.png'
import desc1 from '../../assets/images/bardesc.png'
import desc2 from '../../assets/images/butterdesc.png'
// import Card1 from '../../assets/images/New Project.png';
// import Card2 from '../../assets/images/New Project1.png';
// import Card3 from '../../assets/images/New Project4.png';

// import LifeStyle1 from '../../assets/images/lifestyle1 (1).jpg'
// import LifeStyle2 from '../../assets/images/lifestyle2.png'
// import LifeStyle3 from '../../assets/images/lifeStyle3.jpg'
// import cup from '../../assets/images/acup-flavors/Coffe Dream1.png'
// import bar from '../../assets/images/bars/ABar valhalla final  hi_res.png'
// import jar from '../../assets/images/jars/peanut-big.png'

// import { LazyLoadImage } from 'react-lazy-load-image-component';

gsap.registerPlugin(ScrollTrigger);
function Home() {

  const containerRef = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        pin: true,
        start: 'top top',
        end: '+=200%',
        scrub: 1,
      },
    });
  
    tl.to('.section23', {
      xPercent: -100 * 2,
      duration: 2,
      ease: 'power2.out',
    });
  
    return () => {
      tl.kill(); // Kill the GSAP timeline
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);
  
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <section id="top">
        <Swiper
          autoplay={{
            delay: 4000,
            disableOnInteraction:false,
          }}
          speed={1000} 
          effect="slide"
          loop={true} 
          modules={[Autoplay, Pagination]}
          className="mySwiper tophero"
        >
          
          <SwiperSlide className="slide1"></SwiperSlide>
          <SwiperSlide className="slide2"></SwiperSlide>
          <SwiperSlide className="slide3"></SwiperSlide>
          <SwiperSlide className="slide4"></SwiperSlide>
        </Swiper>
      </section>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className='button'>
<Link to='shop'>
        <div id="linkbtn">
          <h2>Shop Now</h2>
        </div></Link>
</div>
      </div>
<div className='desc'>
      <section id='insides' className="text-center" ref={containerRef}>
        <div className='container'>
        <h2 className='fw-medium' style={{marginTop:'25px'}}>Since 2016, Abrands manufactures a range of healthy snacks aimed at empowering our community. Proudly expanded across the Lebanese market.</h2>
        <div id='animate' className='text-center'>
      <div className="section23" style={{marginLeft:'-100px'}}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg d-flex align-items-center'>
              <img src={ACUP} alt="" width={350}/>
            </div>
            <div className='col-lg d-flex align-items-center'>
              <img src={desc} alt="" width={350}/>
            </div>
</div>
          </div>
      </div>
      <div className="section23">
        
      <div className='container'>
          <div className='row'>
            <div className='col-lg d-flex align-items-center'>
              <img src={ABAR} alt="" width={350}/>
            </div>
           
            <div className='col-lg d-flex align-items-center'>
              <img src={desc1} alt="" width={350}/>
            </div>
</div>
          </div>
      </div>
      <div className="section23">
          
      <div className='container'>
          <div className='row'>
            <div className='col-lg d-flex align-items-center'>
              <img src={ABUTTER} alt="" width={350}/>
            </div>
            
            <div className='col-lg d-flex align-items-center'>
              <img src={desc2} alt="" width={350}/>
            </div>
</div>
          </div>
         
      </div>
      </div>
        </div>
      </section>
  </div>
  <div className='mob' style={{backgroundColor:'#FFBF6D'}}>
    <section className='cards text-center'>
    <div className='container' style={{padding:'50px'}}>
        <h2 className='fw-medium' style={{marginTop:'25px'}}>Since 2016, Abrands manufactures a range of healthy snacks aimed at empowering our community. Proudly expanded across the Lebanese market.</h2>
        <div className='row' style={{marginTop:'50px'}}>
          <div className='col-lg'data-aos="fade-up">
              <img src={desc} alt=""  className='img-fluid cardcup'/>
          </div>
          <div className='col-lg'data-aos="fade-up" style={{marginTop:'20px'}}>
              <img src={desc1} alt="" className='img-fluid card2'/>
          </div>
          <div className='col-lg'data-aos="fade-up" style={{marginTop:'20px'}}>
              <img src={desc2} alt="" className='img-fluid card3'/>
          </div>
        </div>
        </div>
    </section>
  </div>
<section className='lifeStyle'>
<div className='lifestyleBoxes'>
  <div className='col-lg-4 col-md-12'>
<div className='lifebox1 '>
{/* <LazyLoadImage src={LifeStyle1} width={200}/> */}
</div>
  </div>
  <div className='col-lg-4 col-md-12'>
<div className='lifebox2'>
{/* <LazyLoadImage src={LifeStyle2} width={200}/> */}
</div>
  </div>
  <div className='col-lg-4 col-md-12'>
<div className='lifebox3'>
{/* <LazyLoadImage src={LifeStyle3} width={200}/> */}
</div>
  </div>
</div>
</section>
<section id='slogan'>
<div className='container text-center'>
  <div className='text'>
  <h1>Your Healthy<br/>LifeStyle Snack!</h1>
  </div>
</div>
</section>
      <section id="products">
        <div className="text">
          <h2>Our Top Sellers</h2>
          <div className="viewdesc">
            <Link to="shop" id="texttoview">
              <h5>See all of our products</h5>
            </Link>
          </div>
        </div>
        <div className="container-fluid prs">
          <div className="row">
            {PRODUCTS.map((product) => {
              if (product.rate === 'Top') {
                return <TopSeller data={product} key={product.id} />;
              }
              return null;
            })}
          </div>
        </div>
          <div className="viewmob">
            <Link to="shop" id="texttoview">
              <h5>See all of our products</h5>
            </Link>
          </div>
      </section>
    </>
  );
}

export default Home;
