import React, { useState, useEffect } from 'react';
import './App.css';


import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './pages/headerFooter/header';
import Preloader from './components/preloader';
import WhatsAppButton from './components/whatsapp/whatsappbtn';



function App() {


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 7000);

    return () => clearTimeout(timer);
  }, []);



  return (
    <>

    {loading &&
    <Preloader/>
    }

  <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
    <Header loading={loading}/>
  <WhatsAppButton/>
    </div>
  </>
  )
}

export default App;
