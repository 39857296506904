// WhatsAppButton.js

import React from 'react';
import './whatsappbtn.css'; // Create this CSS file for styling

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '96170101004';
    const whatsappUrl = `https://wa.me/${phoneNumber}`;

    // Open WhatsApp link in a new tab
    window.open(whatsappUrl, '_blank');
  };

  return (
    <button className="whatsapp-button" onClick={handleWhatsAppClick}>
      Order on WhatsApp
    </button>
  );
};

export default WhatsAppButton;
